import { type Atom, type WritableAtom, atom } from 'jotai';
import { atomWithStorage, selectAtom } from 'jotai/utils';
import { jwtDecode } from 'jwt-decode';

export interface Credentials {
  access: string | null;
  refresh: string | null;
  /**
   * Last activity timestamp (seconds since unix epoch)
   * */
  lastActivity: number;
  /**
   * Last login timestamp (seconds since unix epoch)
   */
  lastLogin: number | null;
}

export function getNowInSeconds() {
  return +(+new Date() / 1000).toFixed();
}

export const credentialsAtom: ReturnType<typeof atomWithStorage<Credentials>> =
  atomWithStorage<Credentials>('q_auth', {
    access: null,
    refresh: null,
    lastActivity: getNowInSeconds(),
    lastLogin: null,
  });

export interface User {
  /**
   * User ID
   * */
  sub: number;
  /**
   * Issued at timestamp (seconds since unix epoch)
   */
  iat: number;
  /**
   * Expiration timestamp (seconds since unix epoch)
   * */
  exp: number;
  /**
   * JWT ID
   * */
  jti: string;
  version: string;
  token: string;
}

const accessSelectorAtom = selectAtom(credentialsAtom, (s) => s.access);
export const userAtom: WritableAtom<User | null, [string | null], undefined> =
  atom<User | null, [string | null], undefined>(
    (get) => {
      const value = get(accessSelectorAtom);
      if (!value) return null;
      const decoded = jwtDecode<User>(value);
      return {
        ...decoded,
        token: value,
      };
    },
    (get, set, token) => {
      set(credentialsAtom, {
        ...get(credentialsAtom),
        access: token,
        lastLogin: getNowInSeconds(),
      });
    }
  );

const refreshSelectorAtom = selectAtom(credentialsAtom, (s) => s.refresh);
export const refreshAtom = atom<User | null, [string | null], undefined>(
  (get) => {
    const value = get(refreshSelectorAtom);
    if (!value) return null;
    return jwtDecode<User>(value);
  },
  (get, set, token) => {
    set(credentialsAtom, {
      ...get(credentialsAtom),
      refresh: token,
    });
  }
);

// Using this atom, we would be able to modify the inactivity treshold using env vars
export const inactivitySecondsAtom: ReturnType<typeof atom<number>> = atom(
  10 * 60
);

export const lastActivitySelectorAtom: Atom<number> = selectAtom(
  credentialsAtom,
  (s) => s.lastActivity
);
export const lastActivityAtom: WritableAtom<number, [_v: unknown], void> = atom(
  (get) => get(lastActivitySelectorAtom),
  (get, set, _value) => {
    const debug = typeof window !== 'undefined' && window?.__Q_DI;
    const credentials = get(credentialsAtom);
    const now = getNowInSeconds();
    const passedSeconds = now - credentials.lastActivity;
    const allowedInactivitySeconds = get(inactivitySecondsAtom);
    if (debug) {
      const accessExp = get(userAtom)?.exp;
      const refreshExp = get(refreshAtom)?.exp;
      console.log(
        '%c__Q_DI set. This will show debug information for inactivity. Take into account this will only trigger every 5 seconds.',
        'background: #222; color: #bada55',
        `
Inactivity: ${passedSeconds} / ${allowedInactivitySeconds}.${passedSeconds > allowedInactivitySeconds ? ' Expired.' : ''}
Now:  ${new Date(now * 1000).toISOString()}
Last: ${new Date(credentials.lastActivity * 1000).toISOString()}
Access Exp.:  ${accessExp && new Date(accessExp * 1000).toISOString()}
Refresh Exp.: ${refreshExp && new Date(refreshExp * 1000).toISOString()}
`
      );
    }
    if (passedSeconds > allowedInactivitySeconds)
      return set(credentialsAtom, {
        access: null,
        refresh: null,
        lastActivity: now,
        lastLogin: null,
      });
    set(credentialsAtom, {
      ...credentials,
      lastActivity: now,
    });
  }
);
